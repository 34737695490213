import { library } from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome, faJs, faVuejs } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css/swiper.min.css'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import BackToTop from 'vue-backtotop'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueMeta from 'vue-meta'
import VueMobileDetection from 'vue-mobile-detection'
import VueSilentbox from 'vue-silentbox'
import VueSweetalert2 from 'vue-sweetalert2'
import '../src/assets/css/animate.min.css'
import '../src/assets/css/flaticon.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/scss/style.scss'
import App from './App.vue'
import router from './router'

Vue.use(VueFormWizard)

library.add(faUserSecret, faFontAwesome, faJs, faVuejs)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueSilentbox)
Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueMobileDetection)
Vue.use(VueSweetalert2)

Vue.config.productionTip = false

Vue.directive( 'chosen', {
  bind: function ( el, binding, vnode, oldVnode ) {

    Vue.nextTick( function () {

      $(el).chosen( {
        width: '100%'
      }).change( function () {

        alert( $(el).val() );
        vnode.context.$emit( 'input', $(el).val() );

      } );
    } );

  },
  update: function ( el, binding, vnode, oldVnode ) {
  }
});

new Vue({
  router,
  VueMobileDetection,
  render: h => h(App)
}).$mount('#app')
