import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
  },
  {
    path: '/',
    name: 'HomeOne',
    component: () => import(/* webpackChunkName: "home-one" */ '../views/HomeOne.vue')
  },
  {
    path: '/nosotros',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "nosotros" */ '../views/AboutUs.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/carrito',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue')
  },
  {
    path: '/pagar',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
  },
  {
    path: '/activar',
    name: 'ActivateAccount',
    component: () => import(/* webpackChunkName: "activate-account" */ '../views/ActivateAccount.vue')
  },
  {
    path: '/payforu',
    name: 'PayForU',
    component: () => import(/* webpackChunkName: "payforu" */ '../views/PayForU.vue')
  },
  {
    path: '/verificar',
    name: 'VerifyUser',
    component: () => import(/* webpackChunkName: "verificar" */ '../views/VerifyUser.vue')
  },
  {
    path: '/confirmar',
    name: 'Authorize',
    component: () => import(/* webpackChunkName: "autorizar" */ '../views/Authorize.vue')
  },
  {
    path: '/ordenes',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "ordenes" */ '../views/Orders.vue')
  },
  {
    path: '/rutaunegocios',
    name: 'RutaUBusiness',
    component: () => import(/* webpackChunkName: "rutaunegocios" */ '../views/RutaUBusiness.vue')
  },
  {
    path: '/rutauagentes',
    name: 'RutaUAgents',
    component: () => import(/* webpackChunkName: "rutauagentes" */ '../views/RutaUAgents.vue')
  },
  {
    path: '/williu',
    name: 'WilliUBusiness',
    component: () => import(/* webpackChunkName: "williu" */ '../views/WilliUBusiness.vue')
  },
  {
    path: '/malllikeu',
    name: 'MallLikeU',
    component: () => import(/* webpackChunkName: "malllikeu" */ '../views/MallLikeU.vue')
  },
  {
    path: '/likeucreative',
    name: 'LikeUCreative',
    component: () => import(/* webpackChunkName: "likeucreative" */ '../views/LikeUCreative.vue')
  },
  {
    path: '/restablecer',
    name: 'Recovery',
    component: () => import(/* webpackChunkName: "restablecer" */ '../views/Recovery.vue')
  },
  {
    path: '/contrasena',
    name: 'NewPassword',
    component: () => import(/* webpackChunkName: "contraseña" */ '../views/NewPassword.vue')
  },
  {
    path: '/farmau',
    name: 'FarmaU',
    component: () => import(/* webpackChunkName: "farmau" */ '../views/FarmaU.vue')
  },
  {
    path: '/rutau/terminos',
    name: 'RutaUTerms',
    component: () => import(/* webpackChunkName: "rutau-terminos" */ '../views/RutaUTerms.vue')
  },
  {
    path: '/orangeu',
    name: 'Orange',
    component: () => import(/* webpackChunkName: "orange" */ '../views/Orange.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
